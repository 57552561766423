import React, { useContext } from 'react'
import { ProjectContext } from '../../ProjectContext'

export default function Hero() {
  const { dashD } = useContext(ProjectContext)

  return (
    <div className="bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-12 col-xl-12 col-lg-12">
            <div className="banner__content-wrapper text-center p-relative z-index-11">
              <div className="banner__content wow fadeIn" data-wow-delay=".3s">
                <h2 className='text-black'><span className="gradient-text-1">
                  Enter the New Era of </span> Decentralized Banking</h2>
                <p className='fs-1 text-blue'>
                  Deposit digital assets & start banking with us
                </p>
              </div>
              <div className="mt-2">
                <div className=" p-2">
                  <div className="brand__area-6 theme-bg-1 section-rounded-60 p-5">
                    <div className="row">
                      <div className="col-sm-4">
                        <h1 className><span className="gradient-text-1">${dashD ? dashD.t_staking:'0'}+</span></h1>
                        <p>Total Deposits </p>
                      </div>
                      <div className="col-sm-4"><h1 className><span className="gradient-text-1">{dashD ? dashD.t_users:'0'}+</span></h1>
                        <p>Active Users </p>
                      </div>
                      <div className="col-sm-4"><h1 className><span className="gradient-text-1">${dashD ? dashD.t_withdrawal:'0'}
                      </span></h1>
                        <p>Total Claimed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
