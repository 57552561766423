import React, { useContext, useEffect, useState } from "react";
import Dashbordheader from "./Dashbordheader";
import Sidebar from "./Sidebar";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";
import Pagination from "../Coman/Pagination";

export default function Withdrawal() {
  const { fdpkg, rdpkg, copyaddress,
    account,
    balance,
    rate,
    smuser, dbbalance,
    toastSuccess,
    library,
    dbuser,
    tokenrate, formatAddress,
    stakcontract,
    toastError,
    getudata,
    settings,
    esigner,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)
  const [amt, setamt] = useState(0);
  const [token, settoken] = useState(0);
  const [fee, setfee] = useState(0);
  const [tokenRcv, settokenRcv] = useState(0);
  const [data, setdata] = useState([]);
  const [datap, setdatap] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const withdraw = async () => {
    if (!account) {
      toastError("Connect first");
      return;
    }
    console.log("!dbuser", dbuser);
    if (!dbuser) {
      return;
    }
    if (!amt) {
      toastError("Enter amount");
      return;
    }
    if (!dbuser.status) {
      toastError("Your account is not active");
      return;
    }
    if (dbuser.restake) {
      toastError("Please restake your account");
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        address: account,
        amount: amt,
        token: token,
        rate: tokenrate,
        fee: fee,
        t_rcv: tokenRcv,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        getDataPending();
        getuserDB();
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }

        toastSuccess(res.data.data);
      });
  };
  const getDataPending = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        setisLoading(false);

        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getpbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        setisLoading(false);

        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdatap(res.data.data);
      });
  };
  useEffect(() => {
    getDataPending();
  }, [account]);

  useEffect(() => {
    var tmt = amt / tokenrate;
    // var tmtfee = (tmt * 5) / 100;
    var tmtfee = 0.11;
    setfee(tmtfee)
    settoken(tmt);
    settokenRcv(tmt - tmtfee);
  }, [amt, tokenrate]);

  return (
    <div>
      <Dashbordheader />
      <main>
        <div className="style__guide-area section-space">
          <div className="container-fluid">
            <div className="row g-5 justify-content-center">
              <div className="col-xl-2 col-lg-4">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-lg-8">
                <h3 class="style__guide-title mb-20 text-center">Withdrawal</h3>
                <div className="row g-5 justify-content-center">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div
                      className="price__item wow fadeIn"
                    >
                      <div className="price__top">
                        <div className="style__guide-title mb-4  d-flex justify-content-between">
                          <h4>Available Balance</h4>
                          <h4>$ {dbbalance}</h4>
                        </div>
                        <div className="contact__input">
                          <input
                            name="cSDCanyname"
                            onChange={(e) =>
                              setamt(Number(e.target.value))
                            } placeholder="Enter Amount"
                          />
                        </div>
                        <div className="price__value">
                          <p className="mt-4 text-white">
                            Rate: $ {tokenrate}
                          </p>
                          <p className="mt-4 text-white">
                            Fee: $ {fee}
                          </p>
                          <p className="mt-4 text-white">
                            Total token: {tokenRcv}{" "}
                            {process.env.REACT_APP_TICKER}
                          </p>
                          <hr />
                        </div>
                        <div className="d-flex justify-content-center">
                          <button className="bd-gradient-btn btn-xs " onClick={() => withdraw()}>
                            Withdraw now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="card">
                  <div className="row">
                    {/* Grid column */}
                    <div className="col-md-12">
                      <h2 className="py-3 text-center font-bold font-up blue-text">Pending Withdrawal</h2>
                    </div>
                    {/* Grid column */}
                  </div>
                  <div className="card-body">
                    {/* Grid row */}
                    {/* Grid row */}
                    {/*Table*/}
                    <table className="table table-hover table-responsive mb-0">
                      {/*Table head*/}
                      <thead>
                        <tr>
                          <th scope="row">#</th>
                          <th className="th-lg"><a>Amount</a></th>
                          <th className="th-lg"><a href>Token</a></th>
                          <th className="th-lg"><a href>Fee</a></th>
                          {/* <th className="th-lg"><a href>Total Recieve</a></th> */}
                          <th className="th-lg"><a href>Rate</a></th>
                          <th className="th-lg"><a href>Datetime</a></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          {isLoading ? "Data is loading" : ""}
                        </tr>
                        {!isLoading ? (
                          !datap.length ? (
                            <tr className="text-center">
                              <td className="text-center" colSpan={7}>
                                <span className="w-100">No data found</span>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {datap.map((ele, i) => {
                          return (
                            <tr>
                              <td >
                                {ele.id}
                              </td>


                              <td >
                                $ {ele.amount}
                              </td>
                              <td >
                                {ele.token}{" "}
                                {process.env.REACT_APP_TICKER}
                              </td>
                              <td >
                                {ele.fee} {process.env.REACT_APP_TICKER}
                              </td>
                              <td >
                                ${ele.rate}
                              </td>
                              <td >
                                {ele.datetime}
                              </td>

                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <br />
                <div className="card">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="py-3 text-center font-bold font-up blue-text">Withdrawal</h2>
                    </div>
                  </div>
                  <div className="card-body">
                    <table className="table table-hover table-responsive mb-0">
                      <thead>
                        <tr>
                          <th scope="row">#</th>
                          <th className="th-lg"><a>Hash</a></th>
                          <th className="th-lg"><a>Amount</a></th>
                          <th className="th-lg"><a href>Token</a></th>
                          <th className="th-lg"><a href>Fee</a></th>
                          <th className="th-lg"><a href>Rate</a></th>
                          <th className="th-lg"><a href>Datetime</a></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          {isLoading ? "Data is loading" : ""}
                        </tr>
                        {!isLoading ? (
                          !data.length ? (
                            <tr className="text-center">
                              <td className="text-center" colSpan={7}>
                                <span className="w-100">No data found</span>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {data.map((ele, i) => {
                          return (
                            <tr>
                              <td >
                                {ele.id}
                              </td>
                              <td >
                                <div >
                                  <span

                                    onClick={() => copyaddress(ele.hash)}
                                  >
                                    {formatAddress(ele.hash)}{" "}
                                    <i className="fa fa-copy"></i>
                                  </span>
                                </div>
                              </td>

                              <td >
                                $ {ele.amount}
                              </td>
                              <td >
                                {ele.token}{" "}
                                {process.env.REACT_APP_TICKER}
                              </td>
                              <td >
                                {ele.fee} {process.env.REACT_APP_TICKER}
                              </td>
                              <td >
                                ${ele.rate}
                              </td>
                              <td >
                                {ele.datetime}
                              </td>

                            </tr>
                          );
                        })}

                      </tbody>
                      {/*Table body*/}
                    </table>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}